.SectionSeven {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  position: relative;
  justify-content: space-between;
}

.SectionSeven .LandscapeImage {
  flex-basis: 100%;
  padding-bottom: 65%;
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 568px) {
  .SectionSeven .LandscapeImage {
    padding-bottom: 40%;
  }
}

@media screen and (min-width: 768px) {
  .SectionSeven {
    margin-top: 20px;
  }

  .SectionSeven .LandscapeImage {
    padding-bottom: 30%;
    background-position: center center;
    background-size: cover;
  }
}

@media screen and (min-width: 1024px) {
  .Text-Wrapper2 {
    left: 15%;
  }
}

@media screen and (min-width: 1280px) {
  .SectionSeven {
    margin-top: 40px;
  }

  .SectionSeven .LandscapeImage {
    background-attachment: fixed;
  }

  .Text-Wrapper2 {
    left: 20%;
  }
}

@media screen and (min-width: 1440px) {}