.Paragraph-Span {
  font-weight: 500;
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1024px) {}

@media screen and (min-width: 1280px) {
  .Paragraph-Span {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: 1440px) {}