.App {
  text-align: center;
}

.BackToTop {
  position: fixed;
  bottom: 2%;
  right: 5%;
  opacity: 0;
  pointer-events: none;
  transition: .4s ease-in;
  outline: none;
  box-shadow: none;
  outline: 0;
}

.BackToTop:focus {
  outline: 0;
  outline: none;
}

.BackToTop svg {
  width: 36px;
  height: 36px;
}

.BackToTop svg path {
  fill: #fff;
  height: 100%;
  width: 100%;
  transform: scale(1.5);
  transition: .3s ease-out all;
}

.BackToTop-Visible {
  cursor: pointer;
  display: block;
  opacity: 0.6;
  pointer-events: all;
  transition: .4s ease-out all;
  z-index: 10;
}

.BackToTop-Paint svg path {
  fill: #fff;
}

.BackToTop-Visible:hover {
  opacity: 1;
  transform: scale(0.95);
}

@media screen and (max-width: 768px) {
  .App {
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (min-width: 768px) {
  .BackToTop {
    right: 3%;
  }
}

@media screen and (min-width: 2000px) {
  .BackToTop svg path {
    transform: scale(2.5);
  }

  .BackToTop {
    right: 3%;
  }

  .BackToTop svg {
    width: 60px;
    height: 60px;
  }
}