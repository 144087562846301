.SectionOne {
  padding: 40px 0;
  margin: 0 10%;
}

.SectionOne p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #eee;
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 568px) {
  .SectionOne {
    margin: 0 15%;
    padding: 20px 0;
  }
}

@media screen and (min-width: 768px) {
  .SectionOne {
    margin: 0 18%;
    padding: 35px 0;
  }

  .SectionOne p {
    line-height: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .SectionOne {
    margin: 0 25%;
  }

  .SectionOne p {
    font-weight: 300;
  }
}

@media screen and (min-width: 1280px) {
  .SectionOne {
    margin: 0 30%;
    padding: 40px 0;
  }

  .SectionOne p {
    line-height: 22px;
  }
}

@media screen and (min-width: 1440px) {
  .SectionOne {
    margin: 0 30%;
    line-height: 24px;
    padding: 60px 0;
  }
}

@media screen and (min-width: 2000px) {
  .SectionOne {
    margin: 0 35%;
    padding: 60px 0;
  }

  .SectionOne p {
    font-size: 20px;
    line-height: 24px;
  }
}