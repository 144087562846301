.SquareImage {
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40.25%;
  display: flex;
  position: relative;
  flex-basis: 50%;
  margin: 0;
  -webkit-box-shadow: 0px 6px 15px -4px rgba(0, 0, 0, 0.20);
  box-shadow: 0px 6px 15px -4px rgba(0, 0, 0, 0.20);
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 768px) {
  .SquareImage {
    padding-bottom: 30%;
    margin: 0;
  }
}

@media screen and (min-width: 1024px) {}

@media screen and (min-width: 1280px) {}

@media screen and (min-width: 1440px) {}