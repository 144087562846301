* {
  margin: 0;
}

html {
  scroll-behavior: smooth;
  background-color: #08120f;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Navigation,
.SectionOne,
.SectionTwo,
.SectionFour,
.SectionSix,
.SectionEight {
  max-width: 90%;
  margin: auto;
}

h3 {
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}

h5 {
  font-size: 18px;
  line-height: 20px;
  color: #3a3a3a;
}

h3,
h5 {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

p,
a {
  font-family: "Merriweather", serif;
  font-size: 14px;
  color: #515151;
  text-shadow: 0px 4px 4px rgba(0, 40, 35, 0.02);

}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 568px) {

  .Navigation,
  .SectionOne,
  .SectionTwo,
  .SectionFour,
  .SectionSix,
  .SectionEight {
    max-width: 95%;
  }
}

@media screen and (min-width: 768px) {

  .Navigation,
  .SectionOne,
  .SectionTwo,
  .SectionFour,
  .SectionSix,
  .SectionEight {
    max-width: 80%;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
  }

  h5 {
    font-size: 16px;
    line-height: 20px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (min-width: 1024px) {
  h3 {
    font-size: 22px;
    line-height: 26px;
  }

  h5 {
    font-size: 18px;
    line-height: 22px;
  }

  .Navigation,
  .SectionOne,
  .SectionTwo,
  .SectionFour,
  .SectionSix,
  .SectionEight {
    max-width: 70%;
  }
}

@media screen and (min-width: 1280px) {
  h3 {
    font-size: 34px;
    line-height: 38px;

  }

  h5 {
    font-size: 24px;
  }

  .Navigation,
  .SectionOne,
  .SectionTwo,
  .SectionFour,
  .SectionSix,
  .SectionEight {
    max-width: 60%;
  }
}

@media screen and (min-width: 1440px) {
  h3 {
    font-size: 40px;
    line-height: 44px;
    font-weight: 500;
  }

  h5 {
    font-size: 26px;
    line-height: 30px;
  }
}

@media screen and (min-width: 1600px) {
  h3 {
    font-size: 40px;
    line-height: 44px;
  }

  h5 {
    font-size: 30px;
    line-height: 34px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }

}

@media screen and (min-width: 1800px) {
  h3 {
    font-size: 44px;
    line-height: 48px;
  }

  h5 {
    font-size: 34px;
    line-height: 38px;
  }

  p {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (min-width: 2000px) {
  h3 {
    font-size: 48px;
    line-height: 52px;
  }

  h5 {
    font-size: 38px;
    line-height: 42px;
  }

  p {
    font-size: 22px;
    line-height: 26px;
  }
}