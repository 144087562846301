.Navigation {
  display: flex;
  justify-content: space-between;
  height: 100%;
  background-color: #002823;
  align-items: center;
  transition: 2000ms linear background-color;
}

.Navigation-Wrapper--Colored {
  transition: 200ms ease-out transform, 200ms ease-out top,
    2000ms ease-in background-color;
  background-color: #002823;
}

.Navigation-Wrapper {
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 3;
  transform: translateY(0%);
  background-color: #002823;
  transition: transform 300ms ease-out, background-color 200ms linear;
}

.Navigation-Wrapper--Invisible {
  transform: translateY(-450%);
  transition: 300ms ease-in transform, 300ms ease-out top;
}

.LogoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  position: relative;
}

.Logo {
  height: 40px;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .6));
}

@media screen and (min-width: 480px) {
  .Navigation-Wrapper {
    height: 60px;
  }

  .LogoWrapper {
    justify-content: flex-start;
    margin-top: 20px;
  }
}

@media screen and (min-width: 768px) {
  .LogoWrapper {
    align-items: center;
  }

  .Navigation-Wrapper--Colored .LogoWrapper {
    flex-direction: row;
    margin-top: 0;
  }
}

@media screen and (min-width: 2000px) {
  .Navigation-Wrapper {
    height: 100px;
  }

  .Logo {
    height: 45px;
  }

  .LogoWrapper {
    margin-top: 55px;
  }
}