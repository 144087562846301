.BurgerSpan {
  height: 2px;
  width: 20px;
  display: block;
  background-color: #fff;
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.BurgerSpan-top {
  top: 10px;
  height: 2px;
}

.BurgerSpan-center {
  top: 15px;
}

.BurgerSpan-bottom {
  top: 20px;
}

.Burger-Active .BurgerSpan-top {
  top: 15px;
  transform: rotate(45deg);
  background-color: #000;
}

.Burger-Active .BurgerSpan-center {
  display: none;
}

.Burger-Active .BurgerSpan-bottom {
  top: 15px;
  transform: rotate(-45deg);
  background-color: #000;
}

.Burger {
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.Menu-List {
  position: absolute;
  z-index: 3;
  display: flex;
  padding-inline-start: 0;
  list-style-type: none;
  background-color: #fff;
  top: 0;
  flex-direction: column;
  right: 0;
  justify-content: center;
  text-align: center;
  height: 0;
  transition: height 0.5s cubic-bezier(0.3, 1, 0.7, 1);
  width: 100vw;
  transform: translateY(-900%);
}

.Menu-List-Active {
  height: 100vh;
  transform: translateY(0);
  transition: height .4s cubic-bezier(0.3, 1, 0.7, 1);
}

.Menu {
  display: flex;
  width: 100px;
  height: 30px;
  justify-content: flex-end;
  align-items: center;
}

.Menu-List li {
  margin: 10px 20px 20px 20px;
  padding: 2px;
  font-size: 0px;
  line-height: 20px;
  color: #3a3a3a;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  display: none;
}

.Menu-List-Active li {
  font-size: 18px;
  display: block;
}

.Navigation-Right--Content {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 85%;
}

.Navigation-Right--Content p {
  margin-right: 0px;
}

.Languages {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
}

.Languages p,
.ButtonLanguage {
  color: #808080;
  font-family: "Oswald", sans-serif;
}

.ButtonLanguage {
  border: none;
  background-color: transparent;
  outline: none;
  margin: 0 2px;
  font-family: 'Oswald', sans-serif;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  transition: color .3s ease-in;
}

.ButtonLanguage-Active {
  color: #eee;
  transition: color .3s ease-out;
}

.ButtonLanguage:hover {
  color: #737373;
  transition: color .3s ease-out;
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 768px) {
  .Menu-List {
    justify-content: space-between;
    text-align: left;
    right: 8%;
    width: 150px;
    padding: 15px;
    display: none;
  }

  .Menu-List-Active {
    height: 180px;
    display: block;
  }

  .Menu-List li {
    font-size: 14px;
    line-height: 18px;
  }

  .LanguageSplitter {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) {

  .Burger,
  .Menu-List li {
    cursor: pointer;
  }

  .Burger:hover .BurgerSpan-top,
  .Burger:hover .BurgerSpan-center,
  .Burger:hover .BurgerSpan-bottom {
    background-color: #808080;
  }

  .Menu-List li {
    margin: 10px 60px 10px 20px;
  }

  .Menu {
    margin-top: 0;
  }

  .Menu-List {
    right: 14%;
  }
}

@media screen and (min-width: 1280px) {
  .Menu-List {
    right: 19%;
    height: 0;
    transition: height 0.5s cubic-bezier(0.3, 1, 0.7, 1);
    transform: translateY(-900%);
    display: flex;
  }

  .Menu-List-Active {
    transform: translateY(0);
    height: 220px;
    padding: 20px;
  }

  .Menu-List li {
    height: 0;
  }

  .Menu-List-Active li {
    height: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .Menu-List li {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }

  .Menu-List li:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #002823;
    height: 1.5px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  .Menu-List li:hover:before,
  .Menu-List li:focus:before,
  .Menu-List li:active:before {
    right: 0;
  }

  .LanguageSplitter {
    font-size: 15px;
  }

}

@media screen and (min-width: 2000px) {
  .Menu {
    width: 120px;
  }

  .ButtonLanguage {
    font-size: 22px;
  }

  .Navigation-Right--Content {
    width: 100%;
  }

  .BurgerSpan {
    width: 30px;
  }

  .BurgerSpan-bottom {
    top: 22px;
  }

  .BurgerSpan-top {
    top: 8px;
  }

  .Menu-List-Active {
    height: 300px;
    padding: 60px;
    width: 250px;
  }

  .Menu-List {
    right: 18%;
  }

  .Menu-List li {
    font-size: 22px;
    line-height: 26px;
  }

  .LanguageSplitter {
    font-size: 22px;
  }
}