.SectionThree {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  position: relative;
  justify-content: space-between;
}

.Text-Wrapper2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: left;
  color: #fff;
  left: 6%;
  top: 20%;
  background-color: rgba(8, 18, 15, 0.5);
  padding: 30px;
  width: 45%;
}

.Text-Wrapper2 h3 {
  color: #eee;
}

.SectionThree .LandscapeImage {
  flex-basis: 100%;
  padding-bottom: 60%;
}



@media screen and (min-width: 480px) {}

@media screen and (min-width: 568px) {
  .Text-Wrapper2 {
    left: 9%;
  }

  .SectionThree .LandscapeImage {
    padding-bottom: 40%;
  }
}

@media screen and (min-width: 768px) {
  .SectionThree {
    margin-top: 20px;
  }

  .SectionThree .LandscapeImage {
    padding-bottom: 30%;
    background-size: cover;
    background-position: center center;
  }

  .Text-Wrapper2 {
    left: 10%;
    width: 30%;
    top: 25%;
  }
}

@media screen and (min-width: 1024px) {
  .SectionThree .LandscapeImage {
    background-attachment: unset;
  }

  .Text-Wrapper2 {
    left: 15%;
    width: 28%;
    top: 30%;
  }
}

@media screen and (min-width: 1280px) {
  .SectionThree .LandscapeImage {
    background-attachment: fixed;
  }

  .Text-Wrapper2 {
    left: 20%;
    top: 25%;
    width: 25%;
  }
}

@media screen and (min-width: 1440px) {
  .Text-Wrapper2 {
    top: 30%;
  }
}