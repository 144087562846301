.SectionFour {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.SectionFour .SquareImage {
  flex-basis: 50%;
  padding-bottom: 75%;
}

.SectionFour-TextWrapper {
  flex-basis: 45%;
  text-align: left;
}

.SectionFour-Element {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.SectionFour-TextWrapper h3 {
  padding-bottom: 10px;
  padding-top: 15px;
  margin-left: 8px;
  color: #8cc23e;
}

.SectionFour-TextWrapper p {
  padding-bottom: 10px;
  margin: 0 8px;
  color: #eee;
}

.SectionFour-Element {
  margin-bottom: 15px;
}

@media screen and (min-width: 480px) {
  .SectionFour .SquareImage {
    padding-bottom: 48%;
  }
}

@media screen and (min-width: 568px) {
  .SectionFour {
    margin-top: 10px;
  }

  .SectionFour-Element {
    flex-direction: row;
    margin-bottom: 10px;
  }

  .SectionFour-Element--Right {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }

  .SectionFour-Element--Right .SectionFour-TextWrapper {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 40px;
  }

  .SectionFour-TextWrapper {
    text-align: left;
    margin-left: 10px;
    padding-left: 40px;
    padding: 20px;
    padding-top: 30px;
    flex-basis: 42%;
  }

  .SectionFour-TextWrapper h3 {
    padding-top: 0;
    margin-left: 0;
  }

  .SectionFour-TextWrapper p {
    font-size: 11px;
    padding-top: 7px;
    margin: 0;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .SectionFour {
    margin-top: 10px;
  }

  .SectionFour-TextWrapper h3 {
    padding-bottom: 10px;
  }

  .SectionFour-TextWrapper p {
    padding-bottom: 10px;
    font-size: 12px;
  }

  .SectionFour-Element {
    margin-bottom: 5px;
  }

  .SectionFour-TextWrapper {
    padding: 0;
    padding-left: 40px;
    padding-top: 30px;
    flex-basis: 44%;
  }
}

@media screen and (min-width: 1024px) {

  .SectionFour-TextWrapper h3,
  .SectionFour-TextWrapper p {
    padding-bottom: 8px;
  }

  .SectionFour-TextWrapper p {
    font-size: 14px;
    line-height: 20px;
  }

  .SectionFour-TextWrapper {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .SectionFour-Element {
    margin-top: 15px;
  }

  .SectionFour-TextWrapper h3 {
    padding-bottom: 15px;
  }

  .SectionFour-TextWrapper p {
    padding-bottom: 0px;
    font-weight: 500;
  }

  .SectionFour-TextWrapper {
    flex-basis: 45%;
  }
}

@media screen and (min-width: 1440px) {
  .SectionFour-TextWrapper h3 {
    padding-bottom: 20px;
  }

  .SectionFour-TextWrapper p {
    padding-bottom: 20px;
    line-height: 28px;
  }

  .SectionFour {
    margin-top: 10px;
    margin-bottom: 60px;
  }

  .SectionFour-TextWrapper {
    padding-left: 40px;
    flex-basis: 47%;
  }

  .SectionFour-Element {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 2000px) {
  .SectionFour {
    margin-top: 20px;
  }

  .SectionFour-Element {
    margin-bottom: 20px;
  }

  .SectionFour-TextWrapper p {
    font-size: 22px;
    line-height: 26px;
  }

  .SectionFour-TextWrapper {
    margin-left: 30px;
  }
}