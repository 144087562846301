.SectionTwo {
  display: flex;
  flex-direction: column;
}

.SectionTwo-Text {
  display: flex;
  flex-direction: column;
  text-align: left;
  border-left: 0.5px solid #fff;
  margin-top: 20px;
  padding-left: 10px;
  justify-content: space-between;
  height: 80px;
}

.SectionTwo .SquareImage {
  padding-bottom: 70%;
}

.SectionTwo p {
  font-weight: 500;
  color: #eee;
}

.SectionTwo h5 {
  color: #8cc23e;
}

.SectionTwo .SectionElement {
  margin-bottom: 20px;
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 568px) {
  .SectionTwo {
    flex-direction: row;
    justify-content: space-between;
  }

  .SectionTwo .SectionElement {
    flex-basis: 31.5%;
  }

  .SectionTwo p {
    font-size: 11px;
  }
}

@media screen and (min-width: 768px) {
  .SectionTwo .SectionElement {
    margin-bottom: 0;
    flex-basis: 32.3%;
  }

  .SectionTwo .SquareImage {
    padding-bottom: 85%;
  }

  .SectionTwo-Text {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .SectionElement {
    cursor: pointer;
  }

  .SectionTwo .SquareImage {
    transition: .2s ease-out;
    filter: grayscale(20%);
    transform: translateY(0);
  }

  .SectionElement .SquareImage:hover {
    transform: scale(0.99);
    filter: grayscale(0);
    transition: .2s ease-in;
    transform: translateY(1%);
  }

  .SectionTwo p {
    font-size: 12px;
  }
}

@media screen and (min-width: 1280px) {
  .SectionTwo .SectionElement {
    flex-basis: 31.8%;
  }

  .SectionTwo-Text {
    margin-bottom: 100px;
    height: 100px;
    margin-top: 20px;
    padding-left: 20px;
  }

  .SectionTwo h5 {
    font-size: 24px;
    font-weight: 500;
  }

  .SectionTwo p {
    line-height: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .ImageWrapper {
    overflow: hidden;
  }
}

@media screen and (min-width: 2000px) {
  .SectionTwo p {
    font-size: 16px;
    line-height: 20px;
  }
}