.LandscapeImage {
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 56.25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin: 0;
  -webkit-box-shadow: 0px 6px 15px -4px rgba(0, 0, 0, 0.20);
  box-shadow: 0px 6px 15px -4px rgba(0, 0, 0, 0.20);
}

@media screen and (min-width: 480px) {}


@media screen and (min-width: 768px) {
  .LandscapeImage {
    margin: 0px;
  }
}

@media screen and (min-width: 1024px) {}

@media screen and (min-width: 1280px) {}

@media screen and (min-width: 1440px) {
  .LandscapeImage {
    margin: 0;
  }
}