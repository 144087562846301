.Header .Text-Wrapper {
  position: absolute;
  bottom: 15%;
  color: #fff;
  width: 80%;
  padding-left: 10%;
  left: 5%;
  text-align: left;
  padding: 20px 0px 20px 30px;
  background-color: rgba(8, 18, 15, 0.6);
}

.Header .LandscapeImage {
  margin: 0;
  padding-bottom: 80%;
  background-color: #002823;
}

.Text-Wrapper h3 {
  margin-bottom: 10px;
  color: #eee;
  font-weight: 500;
}

.Text-Wrapper p {
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #eee;
}

@media screen and (min-width: 480px) {
  .Header .LandscapeImage {
    padding-bottom: 50%;
  }

  .Header .Text-Wrapper {
    bottom: 20%;
    width: 60%;
    left: 3%;
  }
}

@media screen and (min-width: 768px) {
  .Header .Text-Wrapper {
    text-align: left;
    bottom: 10%;
    padding-left: 20px;
    left: 10%;
    width: 50%;
  }

  .Header .LandscapeImage {
    padding-bottom: 40%;
  }

  .Text-Wrapper h3 {
    margin-bottom: 10px;
  }

  .Text-Wrapper p {
    margin-bottom: 0px;
    line-height: 18px;
    font-weight: 300;
  }
}

@media screen and (min-width: 1024px) {
  .Header .LandscapeImage {
    background-size: 100% 100%;
  }

  .Header .Text-Wrapper {
    text-align: left;
    width: 35%;
    padding: 20px 0px 20px 30px;
    left: 15%;
  }

  .Text-Wrapper p {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1280px) {
  .Header .LandscapeImage {
    background-attachment: fixed;
    background-size: 100% 65%;
    padding-bottom: 30%;
  }

  .Header .Text-Wrapper {
    left: 20%;
  }
}

@media screen and (min-width: 1440px) {}

@media screen and (min-width: 2000px) {
  .Text-Wrapper p {
    font-size: 22px;
    line-height: 26px;
  }
}