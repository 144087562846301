.Content {
  margin: 0;
  min-height: 100%;
  min-height: 100vh;
  margin-bottom: 360px;
  background-color: #08120f;
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 568px) {
  .Content {
    margin-bottom: 350px;
  }
}

@media screen and (min-width: 768px) {
  .Content {
    margin-bottom: 320px;
  }
}

@media screen and (min-width: 1024px) {}

@media screen and (min-width: 1280px) {
  .Content {
    margin-bottom: 350px;
  }
}

@media screen and (min-width: 1440px) {
  .Content {
    margin-bottom: 360px;
  }
}

@media screen and (min-width: 2000px) {
  .Content {
    margin-bottom: 460px;
  }
}