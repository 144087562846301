.Footer {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: space-around;
  align-items: center;
  background-color: #08120f;
  color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: -10;
  padding: 20px 0;
  border-top: 1px solid #fff;
}

.Footer .Logo-Wrapper {
  position: unset;
}

.Footer .Logo {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 1));
}

.Footer h5 {
  color: #d3d3d3;
}

.Footer p,
.Footer a {
  color: #c1c1c1;
  text-decoration: none;
}

.Footer-TextSection--One,
.Footer-TextSection--Two {
  line-height: 20px;
}

.Footer h5 {
  margin-bottom: 5px;
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 568px) {
  .Footer {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: left;
  }

  .Footer .Logo-Wrapper {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .Footer {
    justify-content: center;
    text-align: center;
    padding: 0;
  }

  .Footer .Logo-Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-basis: 20%;
    margin-right: 0px;
  }

  .Footer-TextSection--One,
  .Footer-TextSection--Two {
    margin-left: 40px;
    text-align: left;
  }

  .Footer .Logo {
    height: 80px;
  }

  .Footer-TextSection--One p,
  .Footer-TextSection--Two p {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .Footer-TextSection--One h5,
  .Footer-TextSection--Two h5 {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .Footer {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 9%;
  }

  .Footer-TextSection--One,
  .Footer-TextSection--Two,
  .Footer .Logo-Wrapper {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .Footer {
    padding-left: 13%;
  }
}

@media screen and (min-width: 1440px) {
  .Footer {
    padding-left: 12%;
  }
}

@media screen and (min-width: 2000px) {
  .Footer {
    padding-left: 16.5%;
    height: 400px;
  }

  .Footer .Logo {
    height: 120px;
  }

  .Footer .Ispis {
    height: 100px;
  }

  .Footer-TextSection--One,
  .Footer-TextSection--Two {
    margin-left: 80px;
  }

  .Footer-TextSection--One p,
  .Footer-TextSection--Two p {
    font-size: 22px;
    line-height: 26px;
  }
}