.Paragraph-Regular {
  font-weight: 300;
}

@media screen and (min-width: 480px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1024px) {}

@media screen and (min-width: 1280px) {}

@media screen and (min-width: 1440px) {
  .Paragraph-Regular {
    font-size: 13px;
  }
}